.admin-teachers-subheader {
    margin: 2em auto;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
.admin-teachers-new-teacher {
    margin: 2em 0 0.6em 0 !important;
    border-radius: 0px !important;
    background-color: #009b72 !important;
    color: #fff !important;
    font-size: 16px !important;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
    .admin-teachers-subheader {
        font-size: 16px;
    }
    .admin-teachers-new-teacher {
        margin: 1em 0 0.4em 0 !important;
        font-size: 14px !important;
    }
}
@media screen and (max-width: 400px) {
}
