.admin-wrapper {
    padding: 96px 0 40px 0;
    min-height: 100vh;
}
.admin-header {
    position: fixed;
    overflow: hidden;
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
    background-color: #2d2d2a;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.7);
}
.admin-header nav {
    display: flex;
}
.admin-header-mobile {
    display: none;
}
.admin-header-mobile-button {
    display: none;
}
.admin-nav-link {
    cursor: pointer;
    padding: 16px 24px;
    color: #fffffa;
}
.admin-nav-link:hover {
    color: #95bddb;
}
.admin-nav-link-logout {
    margin-left: auto;
}
.admin-nav-link-active {
    background-color: #1a4f66;
}
@media screen and (max-width: 800px) {
    .admin-wrapper {
        padding: 48px 16px;
    }
    .admin-header {
        display: none;
    }
    .admin-header-mobile {
        display: inherit;
    }
    .admin-header-mobile-button {
        position: fixed;
        z-index: 100;
        right: 24px;
        bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        border-radius: 100%;
        -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
        background-color: #fff;
        cursor: pointer;
    }
    .admin-header-mobile-button img {
        width: 32px;
        height: 24px;
    }
    .admin-header-sidebar {
        width: 300px !important;
        background-color: #222222 !important;
        color: #fff;
    }
    .admin-nav-link {
        font-size: 16px;
    }
}
@media screen and (max-width: 600px) {
    .admin-header-sidebar {
        width: 250px !important;
    }
    .admin-nav-link {
        font-size: 14px;
    }
}
@media screen and (max-width: 400px) {
    .admin-wrapper {
        padding: 40px 8px;
    }
    .admin-header-sidebar {
        width: 200px !important;
    }
    .admin-nav-link {
        font-size: 12px;
    }
}
