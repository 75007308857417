.dashboard-navigation-image-progressbar-container {
    position: fixed;
    width: 64px;
    height: 64px;
    left: 24px;
    top: 24px;
    cursor: pointer;
}
.dashboard-navigation-image-progressbar-circle {
    position: absolute;
    z-index: 100;
}
.dashboard-navigation-image-progressbar-image-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dashboard-navigation-image-progressbar-image {
    height: 36px;
    border-radius: 2em;
}

@media screen and (max-width: 800px) {
    .dashboard-navigation-image-progressbar-container {
        width: 56px;
        height: 56px;
        left: 16px;
        top: 16px;
    }
    .dashboard-navigation-image-progressbar-image {
        height: 32px;
    }
}

@media screen and (max-width: 600px) {
    .dashboard-navigation-image-progressbar-container {
        position: absolute;
        width: 48px;
        height: 48px;
        left: 12px;
        top: 8px;
    }
    .dashboard-navigation-image-progressbar-image {
        height: 24px;
    }
}

@media screen and (max-width: 400px) {
    .dashboard-navigation-image-progressbar-container {
        width: 36px;
        height: 36px;
        left: 8px;
        top: 4px;
    }
    .dashboard-navigation-image-progressbar-image {
        height: 16px;
    }
}
