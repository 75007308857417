.dashboard-practice-question-header {
    margin-bottom: 32px;
    padding: 0px 120px;
    font-size: 22px;
    /* text-transform: uppercase; */
    /* font-weight: 500; */
}
.dashboard-practice-question-text {
    margin-bottom: 32px;
    padding: 0 120px;
    font-size: 20px;
}
.dashboard-practice-question-image {
    margin-bottom: 32px;
    padding: 0 32px;
}
.dashboard-practice-question-hint {
    padding: 0 120px;
}
.dashboard-practice-question-hint-text {
    padding: 24px 48px;
    border-left: 1px solid #000;
}
.dashboard-practice-question-form {
    margin: 32px 24px;
    padding: 40px 64px;
    box-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.05);
    font-size: 18px;
}
.dashboard-practice-question-form-instruction {
    font-size: 18px;
    margin-bottom: 24px;
}
.dashboard-practice-question-explanation-correct {
    margin: 0px 24px;
    padding: 24px 48px;
    border-left: 1px solid #2cd973;
}
.dashboard-practice-question-explanation-wrong {
    margin: 0px 24px;
    padding: 24px 48px;
    border-left: 1px solid #ec0042;
}
.dashboard-practice-question-explanation {
    margin: 0px 24px;
    padding: 24px 48px;
    border-left: 1px solid slategray;
}
.dashboard-practice-question-explanation-correct,
.dashboard-practice-question-explanation-wrong,
.dashboard-practice-question-explanation {
    font-size: 18px;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-question-header {
        padding: 0px 80px;
        font-size: 22px;
    }
    .dashboard-practice-question-text {
        padding: 0 80px;
        font-size: 18px;
    }
    .dashboard-practice-question-image {
        padding: 0 24px;
    }
    .dashboard-practice-question-hint {
        padding: 0 80px;
    }
    .dashboard-practice-question-hint-text {
        font-size: 16px;
        padding-left: 24px;
    }
    .dashboard-practice-question-form {
        margin: 32px 48px;
        padding: 32px 80px;
        font-size: 14px !important;
    }
    .dashboard-practice-question-form-instruction {
        font-size: 18px;
    }
    .dashboard-practice-question-explanation-correct,
    .dashboard-practice-question-explanation-wrong,
    .dashboard-practice-question-explanation {
        padding-left: 40px;
        font-size: 16px;
    }
}
@media only screen and (max-width: 600px) {
    .dashboard-practice-question-header {
        padding: 0px 64px;
        font-size: 20px;
    }
    .dashboard-practice-question-text {
        padding: 0 64px;
        font-size: 18px;
    }
    .dashboard-practice-question-image {
        padding: 0 24px;
    }
    .dashboard-practice-question-hint {
        padding: 0 64px;
    }
    .dashboard-practice-question-hint-text {
        font-size: 16px;
        padding-left: 24px;
    }
    .dashboard-practice-question-form {
        margin: 32px 40px;
        padding: 32px 64px;
        font-size: 14px !important;
    }
    .dashboard-practice-question-form-instruction {
        font-size: 18px;
    }
    .dashboard-practice-question-explanation-correct,
    .dashboard-practice-question-explanation-wrong,
    .dashboard-practice-question-explanation {
        padding-left: 40px;
        font-size: 16px;
    }
}
@media only screen and (max-width: 400px) {
    .dashboard-practice-question-header {
        padding: 0px 40px;
        font-size: 18px;
    }
    .dashboard-practice-question-text {
        padding: 0 40px;
        font-size: 16px;
    }
    .dashboard-practice-question-image {
        padding: 0 16px;
    }
    .dashboard-practice-question-hint {
        padding: 0 40px;
    }
    .dashboard-practice-question-hint-text {
        font-size: 14px;
        padding-left: 24px;
    }
    .dashboard-practice-question-form {
        margin: 16px 24px;
        padding: 32px 40px;
        font-size: 12px !important;
    }
    .dashboard-practice-question-form-instruction {
        font-size: 16px;
    }
    .dashboard-practice-question-explanation-correct,
    .dashboard-practice-question-explanation-wrong,
    .dashboard-practice-question-explanation {
        padding-left: 24px;
        font-size: 14px;
    }
}
