.landing-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1000px;
  overflow-y: hidden;
  font-size: 16px;
  font-weight: 400;
}
.landing-header {
  margin: 48px auto 16px auto;
  max-width: 560px;
  text-align: center;
}
.landing-header img {
  width: 80px;
}
.landing-header h1 {
  margin-bottom: 32px;
  font-size: 40px;
  font-weight: 400;
}
.landing-video {
  margin: 24px 0;
}
.landing-points {
  display: grid;
  grid-template-areas:  "header header header"
                        "text text text";
  margin-bottom: 32px;
  text-align: center;
}
.landing-points > div {
  margin: 0 24px;
  max-width: 560px;
}
.landing-points > div > h3 {
  grid-area: "header";
}
.landing-points > div > p {
  grid-area: "text";
}
.landing-description {
  margin-bottom: 16px;
  text-align: center;
}
.landing-description strong {
  font-weight: 600;
}
.landing-buttons .signButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: max-content;
  margin: 16px auto;
  padding: 12px 20px;
  max-width: 320px;
  background-color: rgba(62, 122, 227);
  border-radius: 12px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}
.landing-buttons .signButton .rightArrow {
  display: inline;
  margin-left: 8px;
  width: 16px;
  height: auto;
}
.landing-buttons button.signButton {
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}
.landing-footer {
  margin-top: 48px;
  margin-bottom: 32px;
  font-size: 18px;
  text-align: center;
}
.landing-chat {
  position: fixed;
  right: 40px;
  bottom: 40px;
  padding: 12px;
  background-color: rgba(62, 122, 227);
  border-radius: 50%;
}
.landing-chat img {
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 640px) {
  .landing-container {
    padding: 0 16px;
    font-size: 14px;
  }
  .landing-header h1 {
    font-size: 32px;
  }
  .landing-description .signButton {
    padding: 12px 16px;
    font-size: 14px;
  }
  .landing-points {
    display: flex;
    flex-direction: column;
  }
  .landing-points > div {
    margin-bottom: 24px;
  }
  .landing-footer {
    font-size: 16px;
  }
  .landing-chat {
    right: 16px;
    bottom: 16px;
    padding: 12px;
  }
  .landing-chat img {
    width: 32px;
    height: 32px;
  }
}
