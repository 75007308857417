.admin-courses-header {
    font-size: 22px;
    text-align: center;
}
.admin-courses-new-course {
    margin: 2em 0 !important;
    border-radius: 0px !important;
    background-color: #009b72 !important;
    color: #fff !important;
    font-size: 16px !important;
}
.admin-courses-card-header {
    font-size: 18px;
}
.admin-courses-card-description {
    font-size: 14px;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
    .admin-courses-header {
        font-size: 16px;
    }
    .admin-courses-new-course {
        margin: 1em 0 !important;
        font-size: 14px !important;
    }
    .admin-courses-card-header {
        font-size: 14px !important;
    }
    .admin-courses-card-description {
        font-size: 10px;
    }
}
@media screen and (max-width: 400px) {
}
