.admin-author-container {
    font-size: 15px;
}
.admin-author-header {
    margin-bottom: 2em;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}
.admin-author-subheader {
    margin-bottom: 1em;
    font-size: 18px;
    text-align: center;
}
.admin-author-block {
    margin-bottom: 1em;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
    .admin-author-header {
        font-size: 20px;
    }
    .admin-author-subheader {
        font-size: 16px;
    }
}
@media screen and (max-width: 400px) {
}
