.auth-modal {
  padding: 54px 40px;
}
.auth-modal-cross {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.auth-modal-cross:hover {
  opacity: 1;
}
.auth-modal-header {
  margin-bottom: 1em;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
}
.auth-modal-header span {
  border-bottom: 1px solid #000;
}
.auth-modal-subheader {
  font-size: 16px;
}
.auth-modal-success-msg {
  margin-bottom: 1em;
  font-size: 18px;
  font-weight: 400;
  color: green;
}
.auth-modal-error-msg {
  margin-bottom: 1em;
  font-size: 18px;
  font-weight: 400;
  color: red;
}
.auth-modal-input {
  width: 100%;
  height: 48px;
  margin-bottom: 1em;
  line-height: 1.6;
  border: none;
  outline: none;
  font-size: 18px;
  box-shadow: 0 1px 0 0 #000;
}
.auth-modal-forgot {
  display: inline;
  cursor: pointer;
  color: #324f7e;
  font-size: 16px;
  font-weight: 400;
}
.auth-modal-forgot:hover {
  border-bottom: 1px solid #324f7e;
}
.auth-modal-buttons {
  font-size: 32px;
  font-weight: 400;
  text-align: right;
}
.auth-modal-buttons > input {
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  margin-bottom: 0;
}
@media only screen and (max-width: 480px) {
  .auth-modal {
    padding: 40px 32px;
    border-radius: 0 !important;
  }
  .auth-modal-cross {
    right: 24px;
    top: 24px;
    width: 32px;
    height: 32px;
  }
  .auth-modal-header {
    font-size: 28px;
    line-height: 28px;
  }
  .auth-modal-input {
    height: 36px;
    margin-bottom: 12px;
    line-height: 18px;
    font-size: 16px;
  }
  .auth-modal-forgot {
    font-size: 14px;
  }
  .auth-modal-buttons {
    font-size: 24px;
  }
}
@media only screen and (max-width: 400px) {
  .auth-modal {
    padding: 36px 24px;
    border-radius: 0 !important;
  }
  .auth-modal-cross {
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
  }
  .auth-modal-header {
    font-size: 22px;
    line-height: 22px;
  }
  .auth-modal-input {
    height: 32px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
  }
  .auth-modal-forgot {
    font-size: 12px;
  }
  .auth-modal-buttons {
    font-size: 20px;
  }
}
