.dashboard-practice-footer-wrapper {
    background: #f2f2f2;
    margin: 0px 16px 40px 16px;
    padding: 32px 96px;
    font-size: 16px;
    text-align: center;
}
.dashboard-practice-footer-header {
    margin-bottom: 24px;
    font-size: 20px;
    text-transform: uppercase;
}
.dashboard-practice-footer-stats {
    display: grid;
    align-content: center;
    justify-content: space-between;
    grid-template-areas:
        "rightText totalText timeText"
        "rightNumber totalNumber timeNumber";
}
.dashboard-practice-footer-stats-right-text,
.dashboard-practice-footer-stats-total-text,
.dashboard-practice-footer-stats-time-text {
    /* text-transform: lowercase; */
}
.dashboard-practice-footer-stats-right-text {
    grid-area: rightText;
}
.dashboard-practice-footer-stats-total-text {
    grid-area: totalText;
}
.dashboard-practice-footer-stats-time-text {
    grid-area: timeText;
}
.dashboard-practice-footer-stats-right-number,
.dashboard-practice-footer-stats-total-number,
.dashboard-practice-footer-stats-time-number {
    font-size: 24px;
    font-weight: bold;
}
.dashboard-practice-footer-stats-right-number {
    grid-area: rightNumber;
}
.dashboard-practice-footer-stats-total-number {
    grid-area: totalNumber;
}
.dashboard-practice-footer-stats-time-number {
    grid-area: timeNumber;
}
.dashboard-practice-footer-questions {
    margin: 0 auto 32px auto;
    width: 80%;
    text-align: center;
}
.dashboard-practice-footer-questions-indefinite {
    color: #47484b;
}
.dashboard-practice-footer-questions-correct {
    color: #198c19;
}
.dashboard-practice-footer-questions-wrong {
    color: #cc0000;
}
.dashboard-practice-footer-extra {
    text-align: left;
}
.dashboard-practice-footer-extra-header {
    margin-bottom: 1em;
    font-weight: 400;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-footer-wrapper {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-practice-footer-header {
        margin-bottom: 20px;
        font-size: 18px;
    }
    .dashboard-practice-footer-stats-right-number,
    .dashboard-practice-footer-stats-total-number,
    .dashboard-practice-footer-stats-time-number {
        font-size: 18px;
    }
    .dashboard-practice-footer-questions {
        margin: 24px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-practice-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 24px 32px;
        font-size: 14px;
    }
    .dashboard-practice-footer-header {
        margin-bottom: 16px;
        font-size: 16px;
    }
    .dashboard-practice-footer-stats-right-number,
    .dashboard-practice-footer-stats-total-number,
    .dashboard-practice-footer-stats-time-number {
        font-size: 16px;
    }
    .dashboard-practice-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-practice-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 16px 32px;
        font-size: 10px;
    }
    .dashboard-practice-footer-header {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .dashboard-practice-footer-stats-right-number,
    .dashboard-practice-footer-stats-total-number,
    .dashboard-practice-footer-stats-time-number {
        font-size: 12px;
    }
    .dashboard-practice-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}
