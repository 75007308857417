.dashboard {
    padding: 104px 112px;
}
.dashboard h1 {
    margin-bottom: 0.6em;
    color: #000;
    font-family: inherit;
    font-size: 36px;
    line-height: 48px;
}
.dashboard h2 {
    margin-bottom: 1.4em;
    color: #000;
    font-family: inherit;
    font-size: 32px;
    line-height: 32px;
}
.dashboard-change-locale {
    position: absolute !important;
    top: 32px;
    right: 120px;
    font-weight: 500;
}

@media screen and (max-width: 800px) {
    .dashboard {
        padding: 88px 88px;
    }
    .dashboard h1 {
        font-size: 30px;
        line-height: 36px;
    }
    .dashboard h2 {
        font-size: 24px;
        line-height: 24px;
    }
    .dashboard-change-locale {
        top: 24px;
        right: 96px;
    }
}
@media screen and (max-width: 600px) {
    .dashboard {
        padding: 80px 32px;
    }
    .dashboard h1 {
        font-size: 26px;
        line-height: 30px;
    }
    .dashboard h2 {
        font-size: 22px;
        line-height: 22px;
    }
    .dashboard-change-locale {
        top: 24px;
        right: 24px;
    }
}
@media screen and (max-width: 400px) {
    .dashboard {
        padding: 72px 24px;
    }
    .dashboard h1 {
        font-size: 24px;
        line-height: 24px;
    }
    .dashboard h2 {
        font-size: 20px;
        line-height: 20px;
    }
    .dashboard-change-locale {
        top: 16px;
        right: 16px;
    }
}
