.landing-payment-section-container p {
    color: #000;
    font-size: 18px;
}
.landing-payment-section-container .amount-header {
    margin-bottom: 0.4em;
    font-size: 22px;
}
.landing-payment-section-container .amount-text {
    margin-bottom: 0px;
    font-size: 24px;
    font-weight: 400;
}
.landing-payment-section-container .note {
    margin-top: 1em;
    max-width: 450px;
    font-size: 12px;
    font-style: italic;
    line-height: 1.6;
}
.landing-payment-section-container form {
    /* padding: 1em; */
}
.landing-payment-section-container form input {
    margin: 0.8em;
}
.landing-payment-section-container form label {
    font-size: 16px;
}
.landing-payment-section-container .pay-button {
    margin-top: 1em;
}
@media only screen and (max-width: 768px) {
    .landing-payment-section-container p {
        font-size: 16px;
    }
    .landing-payment-section-container .amount-header {
        font-size: 20px;
    }
    .landing-payment-section-container .amount-text {
        font-size: 22px;
    }
    .landing-payment-section-container .note {
        font-size: 10px;
    }
    .landing-payment-section-container form label {
        font-size: 15px;
    }
}
@media only screen and (max-width: 480px) {
    .landing-payment-section-container p {
        font-size: 14px;
    }
    .landing-payment-section-container .amount-header {
        font-size: 18px;
    }
    .landing-payment-section-container .amount-text {
        font-size: 20px;
    }
    .landing-payment-section-container .note {
        font-size: 8px;
        line-height: 1.4;
    }
    .landing-payment-section-container form label {
        font-size: 14px;
    }
}
@media only screen and (max-width: 400px) {
    .landing-payment-section-container p {
        font-size: 12px;
    }
    .landing-payment-section-container .note {
        font-size: 6px;
        line-height: 1.4;
    }
    .landing-payment-section-container form label {
        font-size: 12px;
    }
}
