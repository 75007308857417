body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.admin-wrapper {
    padding: 96px 0 40px 0;
    min-height: 100vh;
}
.admin-header {
    position: fixed;
    overflow: hidden;
    z-index: 100;
    top: 0;
    right: 0;
    left: 0;
    background-color: #2d2d2a;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.7);
}
.admin-header nav {
    display: flex;
}
.admin-header-mobile {
    display: none;
}
.admin-header-mobile-button {
    display: none;
}
.admin-nav-link {
    cursor: pointer;
    padding: 16px 24px;
    color: #fffffa;
}
.admin-nav-link:hover {
    color: #95bddb;
}
.admin-nav-link-logout {
    margin-left: auto;
}
.admin-nav-link-active {
    background-color: #1a4f66;
}
@media screen and (max-width: 800px) {
    .admin-wrapper {
        padding: 48px 16px;
    }
    .admin-header {
        display: none;
    }
    .admin-header-mobile {
        display: inherit;
    }
    .admin-header-mobile-button {
        position: fixed;
        z-index: 100;
        right: 24px;
        bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
        height: 64px;
        border-radius: 100%;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
        background-color: #fff;
        cursor: pointer;
    }
    .admin-header-mobile-button img {
        width: 32px;
        height: 24px;
    }
    .admin-header-sidebar {
        width: 300px !important;
        background-color: #222222 !important;
        color: #fff;
    }
    .admin-nav-link {
        font-size: 16px;
    }
}
@media screen and (max-width: 600px) {
    .admin-header-sidebar {
        width: 250px !important;
    }
    .admin-nav-link {
        font-size: 14px;
    }
}
@media screen and (max-width: 400px) {
    .admin-wrapper {
        padding: 40px 8px;
    }
    .admin-header-sidebar {
        width: 200px !important;
    }
    .admin-nav-link {
        font-size: 12px;
    }
}

.admin-analytics-subheader {
    margin: 2em auto;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
.admin-analytics-search {
    margin: 3em auto;
    width: 80%;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
    .admin-analytics-subheader {
        font-size: 16px;
    }
    .admin-analytics-search {
        margin: 2em auto;
        width: 90%;
    }
}
@media screen and (max-width: 400px) {
}

.admin-authors-subheader {
    margin: 2em auto;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
.admin-authors-new-author {
    margin: 2em 0 0.6em 0 !important;
    border-radius: 0px !important;
    background-color: #009b72 !important;
    color: #fff !important;
    font-size: 16px !important;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
    .admin-authors-subheader {
        font-size: 16px;
    }
    .admin-authors-new-author {
        margin: 1em 0 0.4em 0 !important;
        font-size: 14px !important;
    }
}
@media screen and (max-width: 400px) {
}

.admin-author-container {
    font-size: 15px;
}
.admin-author-header {
    margin-bottom: 2em;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}
.admin-author-subheader {
    margin-bottom: 1em;
    font-size: 18px;
    text-align: center;
}
.admin-author-block {
    margin-bottom: 1em;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
    .admin-author-header {
        font-size: 20px;
    }
    .admin-author-subheader {
        font-size: 16px;
    }
}
@media screen and (max-width: 400px) {
}

.admin-courses-header {
    font-size: 22px;
    text-align: center;
}
.admin-courses-new-course {
    margin: 2em 0 !important;
    border-radius: 0px !important;
    background-color: #009b72 !important;
    color: #fff !important;
    font-size: 16px !important;
}
.admin-courses-card-header {
    font-size: 18px;
}
.admin-courses-card-description {
    font-size: 14px;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
    .admin-courses-header {
        font-size: 16px;
    }
    .admin-courses-new-course {
        margin: 1em 0 !important;
        font-size: 14px !important;
    }
    .admin-courses-card-header {
        font-size: 14px !important;
    }
    .admin-courses-card-description {
        font-size: 10px;
    }
}
@media screen and (max-width: 400px) {
}

.admin-courses-course-header {
    font-size: 22px;
    text-align: center;
}
.admin-courses-course-cover {
    width: 30%;
}
.admin-courses-course-new-lesson {
    margin: 2em 0 !important;
    border-radius: 0px !important;
    background-color: #009b72 !important;
    color: #fff !important;
    font-size: 16px !important;
}
@media screen and (max-width: 800px) {
    .admin-courses-course-cover {
        width: 40%;
    }
}
@media screen and (max-width: 600px) {
    .admin-courses-course-header {
        font-size: 16px;
    }
    .admin-courses-course-cover {
        width: 50%;
    }
    .admin-courses-course-new-lesson {
        margin: 1em 0 !important;
        font-size: 14px !important;
    }
}
@media screen and (max-width: 400px) {
    .admin-courses-course-cover {
        width: 70%;
    }
}

.admin-courses-new-course-cover {
    width: 30%;
}
@media screen and (max-width: 800px) {
    .admin-courses-new-course-cover {
        width: 40%;
    }
}
@media screen and (max-width: 600px) {
    .admin-courses-new-course-cover {
        width: 50%;
    }
}
@media screen and (max-width: 400px) {
    .admin-courses-new-course-cover {
        width: 70%;
    }
}

.admin-block-question-delete-option {
    cursor: pointer;
    display: inline;
    margin-right: 24px;
    border-bottom: 1px solid #000;
    padding-bottom: 2px;
}

.admin-practice-question-delete-option {
    cursor: pointer;
    border-bottom: 1px solid #000;
    padding-bottom: 2px;
}

.admin-users-subheader {
    margin: 2em auto;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
.admin-users-search {
    margin: 3em auto;
    width: 80%;
}
.admin-users-new-user {
    margin: 2em 0 0.6em 0 !important;
    border-radius: 0px !important;
    background-color: #009b72 !important;
    color: #fff !important;
    font-size: 16px !important;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
    .admin-users-subheader {
        font-size: 16px;
    }
    .admin-users-search {
        margin: 2em auto;
        width: 90%;
    }
    .admin-users-new-user {
        margin: 1em 0 0.4em 0 !important;
        font-size: 14px !important;
    }
}
@media screen and (max-width: 400px) {
}

.admin-user-container {
    font-size: 15px;
}
.admin-user-header {
    margin-bottom: 2em;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}
.admin-user-subheader {
    margin-bottom: 1em;
    font-size: 18px;
    text-align: center;
}
.admin-user-block {
    margin-bottom: 1em;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
    .admin-user-header {
        font-size: 20px;
    }
    .admin-user-subheader {
        font-size: 16px;
    }
}
@media screen and (max-width: 400px) {
}

.admin-teachers-subheader {
    margin: 2em auto;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
.admin-teachers-new-teacher {
    margin: 2em 0 0.6em 0 !important;
    border-radius: 0px !important;
    background-color: #009b72 !important;
    color: #fff !important;
    font-size: 16px !important;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
    .admin-teachers-subheader {
        font-size: 16px;
    }
    .admin-teachers-new-teacher {
        margin: 1em 0 0.4em 0 !important;
        font-size: 14px !important;
    }
}
@media screen and (max-width: 400px) {
}

.admin-teacher-container {
    font-size: 15px;
}
.admin-teacher-header {
    margin-bottom: 2em;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
}
.admin-teacher-subheader {
    margin-bottom: 1em;
    font-size: 18px;
    text-align: center;
}
.admin-teacher-block {
    margin-bottom: 1em;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
    .admin-teacher-header {
        font-size: 20px;
    }
    .admin-teacher-subheader {
        font-size: 16px;
    }
}
@media screen and (max-width: 400px) {
}

.dashboard {
    padding: 104px 112px;
}
.dashboard h1 {
    margin-bottom: 0.6em;
    color: #000;
    font-family: inherit;
    font-size: 36px;
    line-height: 48px;
}
.dashboard h2 {
    margin-bottom: 1.4em;
    color: #000;
    font-family: inherit;
    font-size: 32px;
    line-height: 32px;
}
.dashboard-change-locale {
    position: absolute !important;
    top: 32px;
    right: 120px;
    font-weight: 500;
}

@media screen and (max-width: 800px) {
    .dashboard {
        padding: 88px 88px;
    }
    .dashboard h1 {
        font-size: 30px;
        line-height: 36px;
    }
    .dashboard h2 {
        font-size: 24px;
        line-height: 24px;
    }
    .dashboard-change-locale {
        top: 24px;
        right: 96px;
    }
}
@media screen and (max-width: 600px) {
    .dashboard {
        padding: 80px 32px;
    }
    .dashboard h1 {
        font-size: 26px;
        line-height: 30px;
    }
    .dashboard h2 {
        font-size: 22px;
        line-height: 22px;
    }
    .dashboard-change-locale {
        top: 24px;
        right: 24px;
    }
}
@media screen and (max-width: 400px) {
    .dashboard {
        padding: 72px 24px;
    }
    .dashboard h1 {
        font-size: 24px;
        line-height: 24px;
    }
    .dashboard h2 {
        font-size: 20px;
        line-height: 20px;
    }
    .dashboard-change-locale {
        top: 16px;
        right: 16px;
    }
}

.dashboard-lesson-footer-wrapper {
    background: #f2f2f2;
    /* margin: 0px 16px 40px 16px; */
    padding: 32px 96px;
    font-size: 16px;
    text-align: center;
}
.dashboard-lesson-footer-header {
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 32px;
    /* text-transform: uppercase; */
}
.dashboard-lesson-footer-stats {
    display: grid;
    align-content: center;
    justify-content: space-between;
    grid-template-areas:
        "rightText totalText timeText"
        "rightNumber totalNumber timeNumber";
}
.dashboard-lesson-footer-stats-right-text,
.dashboard-lesson-footer-stats-total-text,
.dashboard-lesson-footer-stats-time-text {
    text-transform: lowercase;
}
.dashboard-lesson-footer-stats-right-text {
    grid-area: rightText;
}
.dashboard-lesson-footer-stats-total-text {
    grid-area: totalText;
}
.dashboard-lesson-footer-stats-time-text {
    grid-area: timeText;
}
.dashboard-lesson-footer-stats-right-number,
.dashboard-lesson-footer-stats-total-number,
.dashboard-lesson-footer-stats-time-number {
    font-size: 24px;
    font-weight: bold;
}
.dashboard-lesson-footer-stats-right-number {
    grid-area: rightNumber;
}
.dashboard-lesson-footer-stats-total-number {
    grid-area: totalNumber;
}
.dashboard-lesson-footer-stats-time-number {
    grid-area: timeNumber;
}
.dashboard-lesson-footer-questions {
    margin: 0 auto 32px auto;
    width: 80%;
    text-align: center;
}
.dashboard-lesson-footer-questions-indefinite {
    color: #47484b;
}
.dashboard-lesson-footer-questions-correct {
    color: #198c19;
}
.dashboard-lesson-footer-questions-wrong {
    color: #cc0000;
}
@media only screen and (max-width: 800px) {
    .dashboard-lesson-footer-wrapper {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-lesson-footer-header {
        margin-bottom: 20px;
        font-size: 18px;
    }
    .dashboard-lesson-footer-stats-right-number,
    .dashboard-lesson-footer-stats-total-number,
    .dashboard-lesson-footer-stats-time-number {
        font-size: 18px;
    }
    .dashboard-lesson-footer-questions {
        margin: 24px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-lesson-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 24px 32px;
        font-size: 14px;
    }
    .dashboard-lesson-footer-header {
        margin-bottom: 16px;
        font-size: 16px;
    }
    .dashboard-lesson-footer-stats-right-number,
    .dashboard-lesson-footer-stats-total-number,
    .dashboard-lesson-footer-stats-time-number {
        font-size: 16px;
    }
    .dashboard-lesson-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-lesson-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 16px 32px;
        font-size: 10px;
    }
    .dashboard-lesson-footer-header {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .dashboard-lesson-footer-stats-right-number,
    .dashboard-lesson-footer-stats-total-number,
    .dashboard-lesson-footer-stats-time-number {
        font-size: 12px;
    }
    .dashboard-lesson-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

.dashboard-lesson-block-question {
    padding: 0px 24px;
}
.dashboard-lesson-block-question-header {
    margin-bottom: 32px;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;
}
.dashboard-lesson-block-question-text {
    margin-bottom: 32px;
    padding: 0px;
    line-height: 1.6;
    font-size: 20px;
}
.dashboard-lesson-block-question-hint {
    line-height: 1.6;
    font-size: 18px;
}
.dashboard-lesson-block-question-hint-button {
    margin-bottom: 32px !important;
}
.dashboard-lesson-block-question-hint-text {
    margin: 0px;
    padding: 24px 32px;
    border-left: 1px solid #000;
}
.dashboard-lesson-block-question-form {
    margin: 32px 0px;
    padding: 48px 72px;
    box-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.05);
}
.dashboard-lesson-block-question-form-instruction {
    font-size: 18px;
    margin-bottom: 32px;
}
.dashboard-lesson-block-question-form-checkbox,
.dashboard-lesson-block-question-form-radio {
    font-size: 18px !important;
    font-style: italic !important;
}
.dashboard-lesson-block-question-form-time {
    font-size: 18px;
}
.dashboard-lesson-block-question-explanation-correct {
    padding: 24px 32px;
    font-size: 18px;
    border-left: 1px solid #2cd973;
}
.dashboard-lesson-block-question-explanation-wrong {
    padding: 24px 32px;
    font-size: 18px;
    border-left: 1px solid #ec0042;
}
.dashboard-lesson-block-question-explanation-indefinite {
    padding: 24px 32px;
    font-size: 18px;
    border-left: 1px solid slategray;
}

@media only screen and (max-width: 800px) {
    .dashboard-lesson-block-question {
        padding: 0px 16px;
    }
    .dashboard-lesson-block-question-header {
        margin-bottom: 24px;
        font-size: 18px;
    }
    .dashboard-lesson-block-question-text {
        margin-bottom: 24px;
        line-height: 1.4;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-hint {
        line-height: 1.4;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-hint-button {
        margin-bottom: 24px !important;
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-hint-text {
        padding: 16px 24px;
    }
    .dashboard-lesson-block-question-form {
        margin: 24px 0px;
        padding: 32px 64px;
    }
    .dashboard-lesson-block-question-form-instruction {
        font-size: 16px;
        margin-bottom: 24px;
    }
    .dashboard-lesson-block-question-form-input {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-checkbox,
    .dashboard-lesson-block-question-form-radio {
        font-size: 16px !important;
    }
    .dashboard-lesson-block-question-form-time {
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-correct {
        padding: 16px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-wrong {
        padding: 16px 24px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-explanation-indefinite {
        padding: 16px 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-lesson-block-question {
        padding: 0px 16px;
    }
    .dashboard-lesson-block-question-header {
        margin-bottom: 16px;
        font-size: 16px;
    }
    .dashboard-lesson-block-question-text {
        margin-bottom: 16px;
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-hint {
        line-height: 1.2;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-hint-button {
        margin-bottom: 16px !important;
        font-size: 14px !important;
    }
    .dashboard-lesson-block-question-hint-text {
        padding: 8px 24px;
    }
    .dashboard-lesson-block-question-form {
        margin: 16px 0px;
        padding: 24px 40px;
    }
    .dashboard-lesson-block-question-form-instruction {
        font-size: 14px;
        margin-bottom: 16px;
    }
    .dashboard-lesson-block-question-form-input {
        font-size: 14px !important;
    }
    .dashboard-lesson-block-question-form-checkbox,
    .dashboard-lesson-block-question-form-radio {
        font-size: 14px !important;
    }
    .dashboard-lesson-block-question-form-time {
        font-size: 14px;
    }
    .dashboard-lesson-block-question-explanation-correct {
        padding: 8px 24px;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-explanation-wrong {
        padding: 8px 24px;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-explanation-indefinite {
        padding: 8px 24px;
        font-size: 14px;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-lesson-block-question {
        padding: 0px;
    }
    .dashboard-lesson-block-question-header {
        margin-bottom: 8px;
        font-size: 14px;
    }
    .dashboard-lesson-block-question-text {
        margin-bottom: 8px;
        line-height: 1.2;
        font-size: 12px;
    }
    .dashboard-lesson-block-question-hint {
        line-height: 1.2;
        font-size: 12px;
    }
    .dashboard-lesson-block-question-hint-button {
        margin-bottom: 8px !important;
        font-size: 12px !important;
    }
    .dashboard-lesson-block-question-hint-text {
        padding: 8px 16px;
    }
    .dashboard-lesson-block-question-form {
        margin: 12px 0px;
        padding: 16px 24px;
    }
    .dashboard-lesson-block-question-form-instruction {
        font-size: 14px;
        margin-bottom: 12px;
    }
    .dashboard-lesson-block-question-form-input {
        font-size: 12px !important;
    }
    .dashboard-lesson-block-question-form-checkbox,
    .dashboard-lesson-block-question-form-radio {
        font-size: 12px !important;
    }
    .dashboard-lesson-block-question-form-time {
        font-size: 12px;
    }
    .dashboard-lesson-block-question-explanation-correct {
        padding: 8px 16px;
        font-size: 12px;
    }
    .dashboard-lesson-block-question-explanation-wrong {
        padding: 8px 16px;
        font-size: 12px;
    }
    .dashboard-lesson-block-question-explanation-indefinite {
        padding: 8px 16px;
        font-size: 12px;
    }
}

.dashboard-materials-container {
    font-size: 14px;
    color: #000;
}
.dashboard-materials-container-header {
    margin-bottom: 2em;
    font-size: 22px;
    text-align: center;
}
.dashboard-materials-container-lesson {
    padding: 1em;
    border-bottom: 1px dashed rgba(105, 105, 105, 0.25);
}
.dashboard-materials-container-lesson-header {
    margin-bottom: 1em;
    font-size: 16px;
    font-weight: 400;
}
@media screen and (max-width: 800px) {
    .dashboard-materials-container {
        padding: 0 40px;
    }
    .dashboard-materials-container-header {
        font-size: 20px;
    }
}
@media screen and (max-width: 600px) {
    .dashboard-materials-container {
        padding: 0 24px;
        font-size: 12px;
    }
    .dashboard-materials-container-header {
        font-size: 18px;
    }
    .dashboard-materials-container-lesson-header {
        font-size: 14px;
    }
}
@media screen and (max-width: 400px) {
    .dashboard-materials-container {
        font-size: 10px;
    }
    .dashboard-materials-container-header {
        font-size: 16px;
    }
    .dashboard-materials-container-lesson-header {
        font-size: 12px;
    }
}

.dashboard-navigation-image-progressbar-container {
    position: fixed;
    width: 64px;
    height: 64px;
    left: 24px;
    top: 24px;
    cursor: pointer;
}
.dashboard-navigation-image-progressbar-circle {
    position: absolute;
    z-index: 100;
}
.dashboard-navigation-image-progressbar-image-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.dashboard-navigation-image-progressbar-image {
    height: 36px;
    border-radius: 2em;
}

@media screen and (max-width: 800px) {
    .dashboard-navigation-image-progressbar-container {
        width: 56px;
        height: 56px;
        left: 16px;
        top: 16px;
    }
    .dashboard-navigation-image-progressbar-image {
        height: 32px;
    }
}

@media screen and (max-width: 600px) {
    .dashboard-navigation-image-progressbar-container {
        position: absolute;
        width: 48px;
        height: 48px;
        left: 12px;
        top: 8px;
    }
    .dashboard-navigation-image-progressbar-image {
        height: 24px;
    }
}

@media screen and (max-width: 400px) {
    .dashboard-navigation-image-progressbar-container {
        width: 36px;
        height: 36px;
        left: 8px;
        top: 4px;
    }
    .dashboard-navigation-image-progressbar-image {
        height: 16px;
    }
}

.dashboard-practice-forbidden,
.dashboard-practice-not-found {
    font-size: 20px;
}
.dashboard-practice-forbidden-image {
    padding-top: 24px;
    width: 50%;
}
.dashboard-practice-not-found-image {
    width: 60%;
}
.dashboard-practice-name {
    margin-bottom: 2em;
    padding: 0 72px;
    font-size: 36px;
    line-height: 1.2;
    text-align: left;
}
.dashboard-practice-name-id {
    margin-right: 1em;
}
.dashboard-practice-container {
    font-size: 18px;
    font-weight: 300;
}
.dashboard-practice-question {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-bottom: 1em;
    border-bottom: 1px dashed rgba(105, 105, 105, 0.25);
}
.dashboard-practice-question p {
    line-height: 1.4;
}
.dashboard-practice-stars {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-bottom: 2em;
}
.dashboard-practice-star {
    margin: 0 16px;
    width: 48px;
    height: 48px;
    opacity: 1;
    -webkit-animation-name: star-animation;
    -webkit-animation-duration: 4s;
    animation-name: star-animation;
    animation-duration: 4s;
}

@-webkit-keyframes star-animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes star-animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.dashboard-practice-next-question-button {
    margin-left: 120px;
}
.dashboard-practice-next-lesson {
    display: flex;
    justify-content: center;
    margin-top: 64px;
}
.dashboard-practice-next-lesson-link,
.dashboard-practice-next-lesson-link-inactive {
    cursor: pointer;
    color: #000;
    font-size: 32px;
    line-height: 1.2;
    margin: 0px 16px;
    text-align: center;
    text-decoration: none;
    /* text-transform: lowercase; */
}
.dashboard-practice-next-lesson-link:hover {
    color: slategrey;
}
.dashboard-practice-next-lesson-link-inactive {
    cursor: default;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-forbidden,
    .dashboard-practice-not-found {
        font-size: 18px;
    }
    .dashboard-practice-forbidden-image,
    .dashboard-practice-not-found-image {
        margin-top: 2em;
        width: 60%;
    }
    .dashboard-practice-name {
        font-size: 32px;
        line-height: 1.6;
    }
    .dashboard-practice-name-id {
        margin-right: 1em;
    }
    .dashboard-practice-container {
        font-size: 16px;
    }
    .dashboard-practice-question {
        margin-bottom: 3em;
    }
    .dashboard-practice-question p {
        line-height: 1.4;
    }
    .dashboard-practice-stars {
        margin-bottom: 3em;
    }
    .dashboard-practice-star {
        margin: 0 24px;
        width: 40px;
        height: 40px;
    }
    .dashboard-practice-next-question-button {
        margin-left: 72px;
    }
    .dashboard-practice-next-lesson {
        margin-top: 4em;
    }
    .dashboard-practice-next-lesson-link,
    .dashboard-practice-next-lesson-link-inactive {
        font-size: 24px;
    }
}
@media only screen and (max-width: 600px) {
    .dashboard-practice-forbidden,
    .dashboard-practice-not-found {
        font-size: 16px;
    }
    .dashboard-practice-forbidden-image,
    .dashboard-practice-not-found-image {
        margin-top: 3em;
        width: 90%;
    }
    .dashboard-practice-name {
        padding: 0 64px;
        font-size: 28px;
        line-height: 1.2;
    }
    .dashboard-practice-name-id {
        margin-right: 1em;
    }
    .dashboard-practice-container {
        font-size: 14px;
    }
    .dashboard-practice-question {
        margin-bottom: 2em;
    }
    .dashboard-practice-question p {
        line-height: 1.2;
    }
    .dashboard-practice-stars {
        margin-bottom: 2em;
    }
    .dashboard-practice-star {
        margin: 0 16px;
        width: 32px;
        height: 32px;
    }
    .dashboard-practice-next-question-button {
        margin-left: 3em;
    }
    .dashboard-practice-next-lesson {
        margin-top: 2em;
    }
    .dashboard-practice-next-lesson-link,
    .dashboard-practice-next-lesson-link-inactive {
        font-size: 22px;
    }
}
@media only screen and (max-width: 400px) {
    .dashboard-practice-forbidden,
    .dashboard-practice-not-found {
        font-size: 14px;
    }
    .dashboard-practice-forbidden-image,
    .dashboard-practice-not-found-image {
        margin-top: 3em;
        width: 90%;
    }
    .dashboard-practice-name {
        padding: 0 40px;
        font-size: 24px;
        line-height: 1.1;
    }
    .dashboard-practice-name-id {
        margin-right: 0.6em;
    }
    .dashboard-practice-container {
        font-size: 10px;
    }
    .dashboard-practice-question {
        margin-bottom: 1.6em;
    }
    .dashboard-practice-question p {
        line-height: 1.1;
    }
    .dashboard-practice-stars {
        margin-bottom: 2em;
    }
    .dashboard-practice-star {
        margin: 0 8px;
        width: 24px;
        height: 24px;
    }
    .dashboard-practice-next-question-button {
        margin-left: 2em;
    }
    .dashboard-practice-next-lesson {
        margin-top: 4em;
    }
    .dashboard-practice-next-lesson-link,
    .dashboard-practice-next-lesson-link-inactive {
        font-size: 18px;
    }
}

.dashboard-practice-footer-wrapper {
    background: #f2f2f2;
    margin: 0px 16px 40px 16px;
    padding: 32px 96px;
    font-size: 16px;
    text-align: center;
}
.dashboard-practice-footer-header {
    margin-bottom: 24px;
    font-size: 20px;
    text-transform: uppercase;
}
.dashboard-practice-footer-stats {
    display: grid;
    align-content: center;
    justify-content: space-between;
    grid-template-areas:
        "rightText totalText timeText"
        "rightNumber totalNumber timeNumber";
}
.dashboard-practice-footer-stats-right-text,
.dashboard-practice-footer-stats-total-text,
.dashboard-practice-footer-stats-time-text {
    /* text-transform: lowercase; */
}
.dashboard-practice-footer-stats-right-text {
    grid-area: rightText;
}
.dashboard-practice-footer-stats-total-text {
    grid-area: totalText;
}
.dashboard-practice-footer-stats-time-text {
    grid-area: timeText;
}
.dashboard-practice-footer-stats-right-number,
.dashboard-practice-footer-stats-total-number,
.dashboard-practice-footer-stats-time-number {
    font-size: 24px;
    font-weight: bold;
}
.dashboard-practice-footer-stats-right-number {
    grid-area: rightNumber;
}
.dashboard-practice-footer-stats-total-number {
    grid-area: totalNumber;
}
.dashboard-practice-footer-stats-time-number {
    grid-area: timeNumber;
}
.dashboard-practice-footer-questions {
    margin: 0 auto 32px auto;
    width: 80%;
    text-align: center;
}
.dashboard-practice-footer-questions-indefinite {
    color: #47484b;
}
.dashboard-practice-footer-questions-correct {
    color: #198c19;
}
.dashboard-practice-footer-questions-wrong {
    color: #cc0000;
}
.dashboard-practice-footer-extra {
    text-align: left;
}
.dashboard-practice-footer-extra-header {
    margin-bottom: 1em;
    font-weight: 400;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-footer-wrapper {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-practice-footer-header {
        margin-bottom: 20px;
        font-size: 18px;
    }
    .dashboard-practice-footer-stats-right-number,
    .dashboard-practice-footer-stats-total-number,
    .dashboard-practice-footer-stats-time-number {
        font-size: 18px;
    }
    .dashboard-practice-footer-questions {
        margin: 24px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .dashboard-practice-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 24px 32px;
        font-size: 14px;
    }
    .dashboard-practice-footer-header {
        margin-bottom: 16px;
        font-size: 16px;
    }
    .dashboard-practice-footer-stats-right-number,
    .dashboard-practice-footer-stats-total-number,
    .dashboard-practice-footer-stats-time-number {
        font-size: 16px;
    }
    .dashboard-practice-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .dashboard-practice-footer-wrapper {
        margin: 0px 24px 48px 24px;
        padding: 16px 32px;
        font-size: 10px;
    }
    .dashboard-practice-footer-header {
        margin-bottom: 20px;
        font-size: 14px;
    }
    .dashboard-practice-footer-stats-right-number,
    .dashboard-practice-footer-stats-total-number,
    .dashboard-practice-footer-stats-time-number {
        font-size: 12px;
    }
    .dashboard-practice-footer-questions {
        margin: 16px auto;
        width: 100%;
    }
}

.dashboard-practice-header-stats {
    background: #f2f2f2;
    margin: 0px 16px 40px 16px;
    padding: 32px 80px;
    font-size: 16px;
    text-align: center;
}
.dashboard-practice-header-stats-header {
    font-size: 20px;
    /* text-transform: uppercase; */
}
.dashboard-practice-header-stats-results {
    display: grid;
    align-content: center;
    justify-content: space-between;
    grid-template-areas:
        "subheader subheader"
        "rightText totalText"
        "rightNumber totalNumber";
}
.dashboard-practice-header-stats-results-subheader {
    grid-area: subheader;
    font-size: 18px;
    /* text-transform: uppercase; */
}
.dashboard-practice-header-stats-results-right-text {
    grid-area: rightText;
    /* text-transform: lowercase; */
}
.dashboard-practice-header-stats-results-total-text {
    grid-area: totalText;
    /* text-transform: lowercase; */
}
.dashboard-practice-header-stats-results-right-number {
    grid-area: rightNumber;
    font-size: 26px;
    font-weight: bold;
    line-height: 1;
}
.dashboard-practice-header-stats-results-total-number {
    grid-area: totalNumber;
    font-size: 26px;
    font-weight: bold;
    line-height: 1;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-header-stats {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-practice-header-stats-header {
        font-size: 18px;
    }
    .dashboard-practice-header-stats-results-subheader {
        font-size: 16px;
    }
    .dashboard-practice-header-stats-results-right-number {
        font-size: 22px;
        line-height: 1;
    }
    .dashboard-practice-header-stats-results-total-number {
        font-size: 22px;
        line-height: 1;
    }
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-header-stats {
        margin: 0px 32px 48px 32px;
        padding: 24px 64px;
        font-size: 16px;
    }
    .dashboard-practice-header-stats-header {
        font-size: 18px;
    }
    .dashboard-practice-header-stats-results-subheader {
        font-size: 16px;
    }
    .dashboard-practice-header-stats-results-right-number {
        font-size: 22px;
        line-height: 1;
    }
    .dashboard-practice-header-stats-results-total-number {
        font-size: 22px;
        line-height: 1;
    }
}
@media only screen and (max-width: 400px) {
    .dashboard-practice-header-stats {
        margin: 0px 24px 32px 24px;
        padding: 16px 40px;
        font-size: 12px;
    }
    .dashboard-practice-header-stats-header {
        font-size: 14px;
    }
    .dashboard-practice-header-stats-results-subheader {
        font-size: 14px;
    }
    .dashboard-practice-header-stats-results-right-number {
        font-size: 18px;
        line-height: 1;
    }
    .dashboard-practice-header-stats-results-total-number {
        font-size: 18px;
        line-height: 1;
    }
}

.dashboard-practice-question-header {
    margin-bottom: 32px;
    padding: 0px 120px;
    font-size: 22px;
    /* text-transform: uppercase; */
    /* font-weight: 500; */
}
.dashboard-practice-question-text {
    margin-bottom: 32px;
    padding: 0 120px;
    font-size: 20px;
}
.dashboard-practice-question-image {
    margin-bottom: 32px;
    padding: 0 32px;
}
.dashboard-practice-question-hint {
    padding: 0 120px;
}
.dashboard-practice-question-hint-text {
    padding: 24px 48px;
    border-left: 1px solid #000;
}
.dashboard-practice-question-form {
    margin: 32px 24px;
    padding: 40px 64px;
    box-shadow: 0 4px 20px 2px rgba(0, 0, 0, 0.05);
    font-size: 18px;
}
.dashboard-practice-question-form-instruction {
    font-size: 18px;
    margin-bottom: 24px;
}
.dashboard-practice-question-explanation-correct {
    margin: 0px 24px;
    padding: 24px 48px;
    border-left: 1px solid #2cd973;
}
.dashboard-practice-question-explanation-wrong {
    margin: 0px 24px;
    padding: 24px 48px;
    border-left: 1px solid #ec0042;
}
.dashboard-practice-question-explanation {
    margin: 0px 24px;
    padding: 24px 48px;
    border-left: 1px solid slategray;
}
.dashboard-practice-question-explanation-correct,
.dashboard-practice-question-explanation-wrong,
.dashboard-practice-question-explanation {
    font-size: 18px;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-question-header {
        padding: 0px 80px;
        font-size: 22px;
    }
    .dashboard-practice-question-text {
        padding: 0 80px;
        font-size: 18px;
    }
    .dashboard-practice-question-image {
        padding: 0 24px;
    }
    .dashboard-practice-question-hint {
        padding: 0 80px;
    }
    .dashboard-practice-question-hint-text {
        font-size: 16px;
        padding-left: 24px;
    }
    .dashboard-practice-question-form {
        margin: 32px 48px;
        padding: 32px 80px;
        font-size: 14px !important;
    }
    .dashboard-practice-question-form-instruction {
        font-size: 18px;
    }
    .dashboard-practice-question-explanation-correct,
    .dashboard-practice-question-explanation-wrong,
    .dashboard-practice-question-explanation {
        padding-left: 40px;
        font-size: 16px;
    }
}
@media only screen and (max-width: 600px) {
    .dashboard-practice-question-header {
        padding: 0px 64px;
        font-size: 20px;
    }
    .dashboard-practice-question-text {
        padding: 0 64px;
        font-size: 18px;
    }
    .dashboard-practice-question-image {
        padding: 0 24px;
    }
    .dashboard-practice-question-hint {
        padding: 0 64px;
    }
    .dashboard-practice-question-hint-text {
        font-size: 16px;
        padding-left: 24px;
    }
    .dashboard-practice-question-form {
        margin: 32px 40px;
        padding: 32px 64px;
        font-size: 14px !important;
    }
    .dashboard-practice-question-form-instruction {
        font-size: 18px;
    }
    .dashboard-practice-question-explanation-correct,
    .dashboard-practice-question-explanation-wrong,
    .dashboard-practice-question-explanation {
        padding-left: 40px;
        font-size: 16px;
    }
}
@media only screen and (max-width: 400px) {
    .dashboard-practice-question-header {
        padding: 0px 40px;
        font-size: 18px;
    }
    .dashboard-practice-question-text {
        padding: 0 40px;
        font-size: 16px;
    }
    .dashboard-practice-question-image {
        padding: 0 16px;
    }
    .dashboard-practice-question-hint {
        padding: 0 40px;
    }
    .dashboard-practice-question-hint-text {
        font-size: 14px;
        padding-left: 24px;
    }
    .dashboard-practice-question-form {
        margin: 16px 24px;
        padding: 32px 40px;
        font-size: 12px !important;
    }
    .dashboard-practice-question-form-instruction {
        font-size: 16px;
    }
    .dashboard-practice-question-explanation-correct,
    .dashboard-practice-question-explanation-wrong,
    .dashboard-practice-question-explanation {
        padding-left: 24px;
        font-size: 14px;
    }
}

.dashboard-practice-practices-list-group {
    margin-bottom: 1.6em;
}
.dashboard-practice-practices-list-group-name {
    margin-bottom: 1em;
    font-size: 18px;
    font-weight: 500;
}
.dashboard-practice-practices-list-block {
    margin-bottom: 1em;
    font-size: 16px;
}
.dashboard-practice-practices-list-link {
    color: #000;
}
.dashboard-practice-practices-list-link:hover {
    color: #646464;
    border-bottom: 1px solid #646464;
}
@media screen and (max-width: 800px) {
    .dashboard-practice-practices-list-group-name {
        font-size: 16px;
    }
    .dashboard-practice-practices-list-block {
        font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    .dashboard-practice-practices-list-group-name {
        font-size: 14px;
    }
    .dashboard-practice-practices-list-block {
        font-size: 12px;
    }
}
@media screen and (max-width: 400px) {
    .dashboard-practice-practices-list-group-name {
        font-size: 14px;
    }
    .dashboard-practice-practices-list-block {
        font-size: 12px;
    }
}

.landing-payment-section-container p {
    color: #000;
    font-size: 18px;
}
.landing-payment-section-container .amount-header {
    margin-bottom: 0.4em;
    font-size: 22px;
}
.landing-payment-section-container .amount-text {
    margin-bottom: 0px;
    font-size: 24px;
    font-weight: 400;
}
.landing-payment-section-container .note {
    margin-top: 1em;
    max-width: 450px;
    font-size: 12px;
    font-style: italic;
    line-height: 1.6;
}
.landing-payment-section-container form {
    /* padding: 1em; */
}
.landing-payment-section-container form input {
    margin: 0.8em;
}
.landing-payment-section-container form label {
    font-size: 16px;
}
.landing-payment-section-container .pay-button {
    margin-top: 1em;
}
@media only screen and (max-width: 768px) {
    .landing-payment-section-container p {
        font-size: 16px;
    }
    .landing-payment-section-container .amount-header {
        font-size: 20px;
    }
    .landing-payment-section-container .amount-text {
        font-size: 22px;
    }
    .landing-payment-section-container .note {
        font-size: 10px;
    }
    .landing-payment-section-container form label {
        font-size: 15px;
    }
}
@media only screen and (max-width: 480px) {
    .landing-payment-section-container p {
        font-size: 14px;
    }
    .landing-payment-section-container .amount-header {
        font-size: 18px;
    }
    .landing-payment-section-container .amount-text {
        font-size: 20px;
    }
    .landing-payment-section-container .note {
        font-size: 8px;
        line-height: 1.4;
    }
    .landing-payment-section-container form label {
        font-size: 14px;
    }
}
@media only screen and (max-width: 400px) {
    .landing-payment-section-container p {
        font-size: 12px;
    }
    .landing-payment-section-container .note {
        font-size: 6px;
        line-height: 1.4;
    }
    .landing-payment-section-container form label {
        font-size: 12px;
    }
}

.auth-modal {
  padding: 54px 40px;
}
.auth-modal-cross {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 40px;
  height: 40px;
  opacity: 0.5;
}
.auth-modal-cross:hover {
  opacity: 1;
}
.auth-modal-header {
  margin-bottom: 1em;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
}
.auth-modal-header span {
  border-bottom: 1px solid #000;
}
.auth-modal-subheader {
  font-size: 16px;
}
.auth-modal-success-msg {
  margin-bottom: 1em;
  font-size: 18px;
  font-weight: 400;
  color: green;
}
.auth-modal-error-msg {
  margin-bottom: 1em;
  font-size: 18px;
  font-weight: 400;
  color: red;
}
.auth-modal-input {
  width: 100%;
  height: 48px;
  margin-bottom: 1em;
  line-height: 1.6;
  border: none;
  outline: none;
  font-size: 18px;
  box-shadow: 0 1px 0 0 #000;
}
.auth-modal-forgot {
  display: inline;
  cursor: pointer;
  color: #324f7e;
  font-size: 16px;
  font-weight: 400;
}
.auth-modal-forgot:hover {
  border-bottom: 1px solid #324f7e;
}
.auth-modal-buttons {
  font-size: 32px;
  font-weight: 400;
  text-align: right;
}
.auth-modal-buttons > input {
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  margin-bottom: 0;
}
@media only screen and (max-width: 480px) {
  .auth-modal {
    padding: 40px 32px;
    border-radius: 0 !important;
  }
  .auth-modal-cross {
    right: 24px;
    top: 24px;
    width: 32px;
    height: 32px;
  }
  .auth-modal-header {
    font-size: 28px;
    line-height: 28px;
  }
  .auth-modal-input {
    height: 36px;
    margin-bottom: 12px;
    line-height: 18px;
    font-size: 16px;
  }
  .auth-modal-forgot {
    font-size: 14px;
  }
  .auth-modal-buttons {
    font-size: 24px;
  }
}
@media only screen and (max-width: 400px) {
  .auth-modal {
    padding: 36px 24px;
    border-radius: 0 !important;
  }
  .auth-modal-cross {
    right: 20px;
    top: 20px;
    width: 24px;
    height: 24px;
  }
  .auth-modal-header {
    font-size: 22px;
    line-height: 22px;
  }
  .auth-modal-input {
    height: 32px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 14px;
  }
  .auth-modal-forgot {
    font-size: 12px;
  }
  .auth-modal-buttons {
    font-size: 20px;
  }
}

.landing-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1000px;
  overflow-y: hidden;
  font-size: 16px;
  font-weight: 400;
}
.landing-header {
  margin: 48px auto 16px auto;
  max-width: 560px;
  text-align: center;
}
.landing-header img {
  width: 80px;
}
.landing-header h1 {
  margin-bottom: 32px;
  font-size: 40px;
  font-weight: 400;
}
.landing-video {
  margin: 24px 0;
}
.landing-points {
  display: grid;
  grid-template-areas:  "header header header"
                        "text text text";
  margin-bottom: 32px;
  text-align: center;
}
.landing-points > div {
  margin: 0 24px;
  max-width: 560px;
}
.landing-points > div > h3 {
  grid-area: "header";
}
.landing-points > div > p {
  grid-area: "text";
}
.landing-description {
  margin-bottom: 16px;
  text-align: center;
}
.landing-description strong {
  font-weight: 600;
}
.landing-buttons .signButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  margin: 16px auto;
  padding: 12px 20px;
  max-width: 320px;
  background-color: rgba(62, 122, 227);
  border-radius: 12px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
}
.landing-buttons .signButton .rightArrow {
  display: inline;
  margin-left: 8px;
  width: 16px;
  height: auto;
}
.landing-buttons button.signButton {
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}
.landing-footer {
  margin-top: 48px;
  margin-bottom: 32px;
  font-size: 18px;
  text-align: center;
}
.landing-chat {
  position: fixed;
  right: 40px;
  bottom: 40px;
  padding: 12px;
  background-color: rgba(62, 122, 227);
  border-radius: 50%;
}
.landing-chat img {
  width: 32px;
  height: 32px;
}

@media only screen and (max-width: 640px) {
  .landing-container {
    padding: 0 16px;
    font-size: 14px;
  }
  .landing-header h1 {
    font-size: 32px;
  }
  .landing-description .signButton {
    padding: 12px 16px;
    font-size: 14px;
  }
  .landing-points {
    display: flex;
    flex-direction: column;
  }
  .landing-points > div {
    margin-bottom: 24px;
  }
  .landing-footer {
    font-size: 16px;
  }
  .landing-chat {
    right: 16px;
    bottom: 16px;
    padding: 12px;
  }
  .landing-chat img {
    width: 32px;
    height: 32px;
  }
}

