.admin-analytics-subheader {
    margin: 2em auto;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
.admin-analytics-search {
    margin: 3em auto;
    width: 80%;
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 600px) {
    .admin-analytics-subheader {
        font-size: 16px;
    }
    .admin-analytics-search {
        margin: 2em auto;
        width: 90%;
    }
}
@media screen and (max-width: 400px) {
}
