.dashboard-practice-forbidden,
.dashboard-practice-not-found {
    font-size: 20px;
}
.dashboard-practice-forbidden-image {
    padding-top: 24px;
    width: 50%;
}
.dashboard-practice-not-found-image {
    width: 60%;
}
.dashboard-practice-name {
    margin-bottom: 2em;
    padding: 0 72px;
    font-size: 36px;
    line-height: 1.2;
    text-align: left;
}
.dashboard-practice-name-id {
    margin-right: 1em;
}
.dashboard-practice-container {
    font-size: 18px;
    font-weight: 300;
}
.dashboard-practice-question {
    margin-top: 24px;
    margin-bottom: 24px;
    padding-bottom: 1em;
    border-bottom: 1px dashed rgba(105, 105, 105, 0.25);
}
.dashboard-practice-question p {
    line-height: 1.4;
}
.dashboard-practice-stars {
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-bottom: 2em;
}
.dashboard-practice-star {
    margin: 0 16px;
    width: 48px;
    height: 48px;
    opacity: 1;
    -webkit-animation-name: star-animation;
    -webkit-animation-duration: 4s;
    animation-name: star-animation;
    animation-duration: 4s;
}

@-webkit-keyframes star-animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes star-animation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.dashboard-practice-next-question-button {
    margin-left: 120px;
}
.dashboard-practice-next-lesson {
    display: flex;
    justify-content: center;
    margin-top: 64px;
}
.dashboard-practice-next-lesson-link,
.dashboard-practice-next-lesson-link-inactive {
    cursor: pointer;
    color: #000;
    font-size: 32px;
    line-height: 1.2;
    margin: 0px 16px;
    text-align: center;
    text-decoration: none;
    /* text-transform: lowercase; */
}
.dashboard-practice-next-lesson-link:hover {
    color: slategrey;
}
.dashboard-practice-next-lesson-link-inactive {
    cursor: default;
}
@media only screen and (max-width: 800px) {
    .dashboard-practice-forbidden,
    .dashboard-practice-not-found {
        font-size: 18px;
    }
    .dashboard-practice-forbidden-image,
    .dashboard-practice-not-found-image {
        margin-top: 2em;
        width: 60%;
    }
    .dashboard-practice-name {
        font-size: 32px;
        line-height: 1.6;
    }
    .dashboard-practice-name-id {
        margin-right: 1em;
    }
    .dashboard-practice-container {
        font-size: 16px;
    }
    .dashboard-practice-question {
        margin-bottom: 3em;
    }
    .dashboard-practice-question p {
        line-height: 1.4;
    }
    .dashboard-practice-stars {
        margin-bottom: 3em;
    }
    .dashboard-practice-star {
        margin: 0 24px;
        width: 40px;
        height: 40px;
    }
    .dashboard-practice-next-question-button {
        margin-left: 72px;
    }
    .dashboard-practice-next-lesson {
        margin-top: 4em;
    }
    .dashboard-practice-next-lesson-link,
    .dashboard-practice-next-lesson-link-inactive {
        font-size: 24px;
    }
}
@media only screen and (max-width: 600px) {
    .dashboard-practice-forbidden,
    .dashboard-practice-not-found {
        font-size: 16px;
    }
    .dashboard-practice-forbidden-image,
    .dashboard-practice-not-found-image {
        margin-top: 3em;
        width: 90%;
    }
    .dashboard-practice-name {
        padding: 0 64px;
        font-size: 28px;
        line-height: 1.2;
    }
    .dashboard-practice-name-id {
        margin-right: 1em;
    }
    .dashboard-practice-container {
        font-size: 14px;
    }
    .dashboard-practice-question {
        margin-bottom: 2em;
    }
    .dashboard-practice-question p {
        line-height: 1.2;
    }
    .dashboard-practice-stars {
        margin-bottom: 2em;
    }
    .dashboard-practice-star {
        margin: 0 16px;
        width: 32px;
        height: 32px;
    }
    .dashboard-practice-next-question-button {
        margin-left: 3em;
    }
    .dashboard-practice-next-lesson {
        margin-top: 2em;
    }
    .dashboard-practice-next-lesson-link,
    .dashboard-practice-next-lesson-link-inactive {
        font-size: 22px;
    }
}
@media only screen and (max-width: 400px) {
    .dashboard-practice-forbidden,
    .dashboard-practice-not-found {
        font-size: 14px;
    }
    .dashboard-practice-forbidden-image,
    .dashboard-practice-not-found-image {
        margin-top: 3em;
        width: 90%;
    }
    .dashboard-practice-name {
        padding: 0 40px;
        font-size: 24px;
        line-height: 1.1;
    }
    .dashboard-practice-name-id {
        margin-right: 0.6em;
    }
    .dashboard-practice-container {
        font-size: 10px;
    }
    .dashboard-practice-question {
        margin-bottom: 1.6em;
    }
    .dashboard-practice-question p {
        line-height: 1.1;
    }
    .dashboard-practice-stars {
        margin-bottom: 2em;
    }
    .dashboard-practice-star {
        margin: 0 8px;
        width: 24px;
        height: 24px;
    }
    .dashboard-practice-next-question-button {
        margin-left: 2em;
    }
    .dashboard-practice-next-lesson {
        margin-top: 4em;
    }
    .dashboard-practice-next-lesson-link,
    .dashboard-practice-next-lesson-link-inactive {
        font-size: 18px;
    }
}
