.dashboard-practice-practices-list-group {
    margin-bottom: 1.6em;
}
.dashboard-practice-practices-list-group-name {
    margin-bottom: 1em;
    font-size: 18px;
    font-weight: 500;
}
.dashboard-practice-practices-list-block {
    margin-bottom: 1em;
    font-size: 16px;
}
.dashboard-practice-practices-list-link {
    color: #000;
}
.dashboard-practice-practices-list-link:hover {
    color: #646464;
    border-bottom: 1px solid #646464;
}
@media screen and (max-width: 800px) {
    .dashboard-practice-practices-list-group-name {
        font-size: 16px;
    }
    .dashboard-practice-practices-list-block {
        font-size: 14px;
    }
}
@media screen and (max-width: 600px) {
    .dashboard-practice-practices-list-group-name {
        font-size: 14px;
    }
    .dashboard-practice-practices-list-block {
        font-size: 12px;
    }
}
@media screen and (max-width: 400px) {
    .dashboard-practice-practices-list-group-name {
        font-size: 14px;
    }
    .dashboard-practice-practices-list-block {
        font-size: 12px;
    }
}
