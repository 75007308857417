.dashboard-materials-container {
    font-size: 14px;
    color: #000;
}
.dashboard-materials-container-header {
    margin-bottom: 2em;
    font-size: 22px;
    text-align: center;
}
.dashboard-materials-container-lesson {
    padding: 1em;
    border-bottom: 1px dashed rgba(105, 105, 105, 0.25);
}
.dashboard-materials-container-lesson-header {
    margin-bottom: 1em;
    font-size: 16px;
    font-weight: 400;
}
@media screen and (max-width: 800px) {
    .dashboard-materials-container {
        padding: 0 40px;
    }
    .dashboard-materials-container-header {
        font-size: 20px;
    }
}
@media screen and (max-width: 600px) {
    .dashboard-materials-container {
        padding: 0 24px;
        font-size: 12px;
    }
    .dashboard-materials-container-header {
        font-size: 18px;
    }
    .dashboard-materials-container-lesson-header {
        font-size: 14px;
    }
}
@media screen and (max-width: 400px) {
    .dashboard-materials-container {
        font-size: 10px;
    }
    .dashboard-materials-container-header {
        font-size: 16px;
    }
    .dashboard-materials-container-lesson-header {
        font-size: 12px;
    }
}
